import React, { useEffect, useRef } from "react";

import './Tooltip.css';

const Tooltip = (props) => {

    const tooltipWrapperRef = useRef(null);
    const tooltipRef = useRef(null);
    const tooltipId = useRef('tooltip-' + Math.random().toString(36).slice(2, 9));

    useEffect(() => {

        const createTooltip = () => {
            const body = document.body;
            const wrapperElt = tooltipWrapperRef.current;

            if(!body || !wrapperElt || !props.message || !props.target.current) return;

            const position = props.position || 'top';
            const pointer = props.pointer || 'middle';

            // Constructing the tooltip
            const tooltipElt = document.createElement('div');
            tooltipRef.current = tooltipElt;

            tooltipElt.classList.add('tooltiptext');
            tooltipElt.setAttribute('position', position);
            tooltipElt.setAttribute('pointer', pointer);
            tooltipElt.id = tooltipId.current;
            tooltipElt.innerHTML = props.message || ``;

            // Pass the user defined (custom) styling
            if(props.style){
                Object.keys(props.style).forEach(styleKey => {
                    // styleKey = styleKey.replace(/([A-Z])/g, "-$1").toLowerCase();
                    tooltipElt.style[styleKey] = props.style[styleKey];
                })
            }
            tooltipElt.style['visibility'] = 'hidden';

            body.appendChild(tooltipElt);
        }

        createTooltip();

        return () => {
            const tooltipElt = tooltipRef.current;
            if(tooltipElt){
                tooltipElt.remove();
            }
        }

     // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {
        const positionTooltip = () => {
            const positionMapping = {
                'top': 'top',
                'bottom': 'top',
                'left': 'left',
                'right': 'left'
            }
    
            const wrapperElt = tooltipWrapperRef.current;
            const tooltipElt = tooltipRef.current;
            const position = props.position || 'top';

            if(!wrapperElt || !tooltipElt) return;
    
            // Positioning the tooltip
            const wrapperPositionRect = wrapperElt.getBoundingClientRect();
            const tooltipPositionRect = tooltipElt.getBoundingClientRect();
            tooltipElt.style[positionMapping[position]] = wrapperPositionRect[position] + 'px';
    
            if(position === 'top' || position === 'bottom'){
                tooltipElt.style['left'] = wrapperPositionRect.left - (tooltipPositionRect.width / 2) + (wrapperPositionRect.width / 2) + 'px'; 
            }
            else if(position === 'left' || position === 'right'){
                tooltipElt.style['top'] = wrapperPositionRect.top - (tooltipPositionRect.height / 2) + (wrapperPositionRect.height / 2) + 'px'; 
            }
        }

        const toggleTooltip = (visibilityValue) => {
            const tooltipElt = tooltipRef.current;
            if(tooltipElt){
                positionTooltip();
                tooltipElt.style.visibility = visibilityValue;
            }
        }

        positionTooltip();
        document.addEventListener('scroll', positionTooltip);
        document.addEventListener('resize', positionTooltip);
        window.addEventListener('resize', positionTooltip);
        
        const targetElt = props.target.current;
        if(targetElt){
            targetElt.addEventListener('mouseover',() => {toggleTooltip('visible');});
            targetElt.addEventListener('mouseout',() => {toggleTooltip('hidden');});

            targetElt.addEventListener('touchstart', () => {toggleTooltip('visible');});
            targetElt.addEventListener('touchend', () => {toggleTooltip('hidden');});

        }

        return () => {
            document.removeEventListener('scroll', positionTooltip);
            document.removeEventListener('resize', positionTooltip);
            window.removeEventListener('resize', positionTooltip);

            if(targetElt){
                targetElt.removeEventListener('mouseover',toggleTooltip);
                targetElt.removeEventListener('mouseout',toggleTooltip);
                targetElt.removeEventListener('touchstart',toggleTooltip);
                targetElt.removeEventListener('touchend',toggleTooltip);
            }            
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return(
        <div className="tooltip-wrapper" ref={tooltipWrapperRef}>
            {props.children}
        </div>
    )
}

export default Tooltip;