import React from 'react';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import './SaasMigrationNote.css';

const SaasMigrationNote = ({ message }) => {
    let sanitizedMessage = DOMPurify.sanitize(message, {
        ADD_ATTR: ['target', 'rel']
    });

    return (
        <div className="saas-migration-note">
            <span className="saas-note-icon">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="20" height="20" fill="white" fillOpacity="0.01" style={{ mixBlendMode: "multiply" }}/>
                    <rect x="5" y="5" width="10" height="11.25" fill="black"/>
                    <rect width="20" height="20" fill="white" fillOpacity="0.01" style={{ mixBlendMode: "multiply" }}/>
                    <path d="M10 1.25C5.1875 1.25 1.25 5.1875 1.25 10C1.25 14.8125 5.1875 18.75 10 18.75C14.8125 18.75 18.75 14.8125 18.75 10C18.75 5.1875 14.8125 1.25 10 1.25ZM9.3125 5H10.6875V11.875H9.3125V5V5ZM10 15.625C9.5 15.625 9.0625 15.1875 9.0625 14.6875C9.0625 14.1875 9.5 13.75 10 13.75C10.5 13.75 10.9375 14.1875 10.9375 14.6875C10.9375 15.1875 10.5 15.625 10 15.625Z" fill="#F1C21B"/>
                </svg>
            </span>
            <p className="saas-note-text">{parse(sanitizedMessage)}</p>
        </div>
    );
};

export default SaasMigrationNote;
