import React, { useRef } from "react";
import Tooltip from 'Components/Tooltip/Tooltip';

import './TabItem.css';
const TabItem = (props) => {

    const tooltipTargetRef = useRef(null);

    if(props.tooltip){
        return(
            <Tooltip 
                message="SaaS Documentation is now on the IBM Documentation portal."
                style={{width:'290px',maxWidth:'290px',position:'fixed'}}
                position="top"
                target={tooltipTargetRef}
            >
                <button 
                    ref={tooltipTargetRef}
                    onClick={() => {if(!props.disabled){props.tabClickHandler(props.id)}}}
                    disabled={props.disabled}
                    className={[
                                "tabs__nav-item tabs__nav-link",props.selected ? "tabs__nav-item--selected" : '',
                                props.disabled ? 'disabled':''].join(' ')} 
                    aria-controls="ccs-:r3:-tabpanel-0" role="tab" tabIndex="0" 
                    type="button"
                >
                    <div className="tabs__nav-item-label-wrapper">
                        <span className="tabs__nav-item-label" dir="ltr">{props.title}</span>
                    </div>
                </button>
            </Tooltip>
        )
    }
    else{
        return(
            <button 
                onClick={() => props.tabClickHandler(props.id)}
                className={["tabs__nav-item tabs__nav-link",props.selected ? "tabs__nav-item--selected" : ''].join(' ')} 
                aria-controls="ccs-:r3:-tabpanel-0" role="tab" tabIndex="0" 
                type="button"
            >
                <div className="tabs__nav-item-label-wrapper">
                    <span className="tabs__nav-item-label" dir="ltr">{props.title}</span>
                </div>
            </button>
        )
    }
}
// tabs__nav-item--selected ariaSelected="true"

export default TabItem;