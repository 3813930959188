import React,{ useEffect, useRef, useState } from "react";
import $ from 'jquery';

import SearchBox from "Components/SearchBox/SearchBox";
import NavBar from 'Components/NavBar/NavBar';
import AnnouncementBanner from "Components/AnnouncementBanner/AnnouncementBanner";

import './Banner.css';
import bannerImage from '../../assets/images/background.png';
import logo from './ibm_light.svg';
import logoDark from '../../assets/images/ibm_dark.svg';

const Banner = () => {

    const IBM_REDIRECTION_MESSAGE = `SaaS Documentation is now on the <a rel="noopener noreferrer" target="_blank" href="https://www.ibm.com/docs/en">IBM Documentation</a> portal. Visit to explore.`;
    
    const bannerContainerRef = useRef(null);
    const announcementBannerRef = useRef(null);
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        window.addEventListener('scroll',() => {
            setScrollPosition(window.scrollY);
        })
    },[]);

    // Changing annoucement banner position
    const setFixedEltOffset =() => { 
        // Getting elements
        const navElt = document.querySelector('#main-nav');
        const bannerElt = announcementBannerRef ? announcementBannerRef.current : null;
        const productsContainerHeader = document.querySelector('.products-container-header');
        const tocElt = document.querySelector('.capabilities-toc');
        
        // Checking if banner is hidden
        const isBannerHidden = bannerElt ? bannerElt.style.display === 'none' : false;

        // Setting deafult value
        let bannerTopOffset = 64;
        let productsContainerHeaderTopOffset = 104;

        // Getting dynamic offset
        if(navElt){
            bannerTopOffset = navElt.getBoundingClientRect().height;
        }

        if(bannerElt){
            productsContainerHeaderTopOffset = bannerTopOffset + (isBannerHidden ? 0 : bannerElt.getBoundingClientRect().height);
        }

        // Setting offsets
        if(bannerElt){
            bannerElt.style.top = `${bannerTopOffset}px`;
        }

        if(productsContainerHeader){
            productsContainerHeader.style.top = `${productsContainerHeaderTopOffset}px`;
        }

        if(tocElt){
            tocElt.style.top = `${productsContainerHeaderTopOffset}px`;
        }
    }

    useEffect(() => { 
        setTimeout(setFixedEltOffset, 500);

        document.addEventListener('DOMContentLoaded', setFixedEltOffset);
        window.addEventListener('resize', setFixedEltOffset);
        window.addEventListener('scroll', setFixedEltOffset);

        return () => {
            window.removeEventListener('scroll', setFixedEltOffset);
            window.removeEventListener('resize', setFixedEltOffset);
            document.removeEventListener('DOMContentLoaded', setFixedEltOffset);
        }

    },[]);

    return(
        <React.Fragment>
            <div id="landing-banner" className="large" ref={bannerContainerRef}>
                    <div id="banner-image-container" style={{backgroundImage:`url(${bannerImage})`}}>
                        <img src={logo} alt="IBM Logo" id="landing-banner-logo" />
                    </div>
                    <div id="logo-container">
                        <img src={logoDark} alt="IBM webMethods Documentation"/>
                    </div>
                    <div id="landing-banner-header">
                        <h1 id="landing-banner-title">IBM webMethods Documentation</h1>
                        <div id="landing-banner-searchbox">
                            <div id="landing-banner-gsc-search">
                                {
                                    scrollPosition <= 300 ?
                                        <React.Fragment>
                                            <style>
                                                {
                                                    `
                                                        /* Auto-complete suggestions styling */
                                                        table.gssb_c {
                                                            top: 236px !important;
                                                            left: 50% !important;
                                                            transform: translateX(-50%) !important;
                                                            width: 70vw !important;
                                                            max-width: 610px;
                                                            min-width: 229px;
                                                            -webkit-transform: translateX(-50%) !important;
                                                            -moz-transform: translateX(-50%) !important;
                                                            -ms-transform: translateX(-50%) !important;
                                                            -o-transform: translateX(-50%) !important;
                                                        }

                                                        @media screen and (max-width: 771px){
                                                            table.gstl_50.gssb_c {
                                                                top: 252px !important;
                                                            }
                                                        }

                                                        @media screen and (max-width: 679px){
                                                            table.gstl_50.gssb_c {
                                                                top: 271px !important;
                                                            }
                                                        }
                                                         @media screen and (max-width: 679px){
                                                            table.gstl_50.gssb_c {
                                                                top: 271px !important;
                                                            }
                                                        }


                                                        @media screen and (max-width:356px) {
                                                            table.gstl_50.gssb_c {
                                                                top: 290px !important;
                                                            }
                                                        }

                                                        /* small banner styling */

                                                        /* Auto-complete suggestions styling */
                                                        .navbar-visible table.gssb_c {
                                                            min-width: 160px !important;
                                                            width: 40vw !important;
                                                            max-width: 360px !important;
                                                            margin-left: -4px;
                                                        }

                                                        @media screen and (max-width: 470px) {
                                                            .navbar-visible table.gssb_c {
                                                                width: 90% !important;
                                                                max-width: 360px !important;
                                                            }
                                                        }
                                                    `
                                                }
                                            </style>
                                            <SearchBox/>
                                        </React.Fragment>
                                    : ''
                                }
                            </div>
                        </div>
                    </div>
            </div>
            {
                scrollPosition >= 305 ?
                <style>
                {
                `
                    .announcement-banner-wrappaer{
                        position: fixed;
                        /*top: 64px;*/
                        z-index: 999;
                    }
                        
                    .main-content{
                        padding-top: 40px;
                    }

                    .capabilities-toc {
                        top: 104px;
                    }

                    .products-container-header {
                        top: 104px;
                    }
                `
                }
                </style>:''
            }
            {
                scrollPosition > 300 ?
                <>
                    <NavBar/>
                </>
                : ''
            }
            <AnnouncementBanner 
                    text={IBM_REDIRECTION_MESSAGE}
                    // bannerClose={false} 
                    bannerCloseHandler={() => { 
                        if(announcementBannerRef && announcementBannerRef.current) {
                            $(announcementBannerRef.current).slideUp('slow');
                        }

                        setTimeout(() => {
                            setFixedEltOffset();
                        },550);
                    }}

                    announcementBannerRef={announcementBannerRef}
                    wrapperClass="ibm-redirection-banner"
                    noAnimation={true}
            />
        </React.Fragment>
    )
}

export default Banner;
