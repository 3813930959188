import React from "react";
import TabItem from "./TabItem/TabItem";

import './TabGroup.css';

const TabGroup = ({ tabList, tabClickHandler}) => {

    const generateTabList = () => {

        const tabListEls = [];
        const selectedTabValid = Object.keys(tabList).filter(tabKey => tabList[tabKey].value && tabList[tabKey].count)
        const fallbackTab = Object.keys(tabList).filter(tabKey => tabList[tabKey].count)[0];

        Object.keys(tabList).forEach((tabKey, idx) =>{
            const tabObj = tabList[tabKey];

            if(tabObj.count !== 0){
                tabListEls.push(
                    <TabItem
                        disabled={tabObj.disabled}
                        tooltip={tabObj.tooltip}
                        tooltipMessage={tabObj.tooltipMessage}
                        title={tabObj.title}
                        selected={selectedTabValid.length ? tabObj.value : (fallbackTab === tabKey ? true : false)}
                        key={tabKey}
                        id={tabKey}
                        tabClickHandler={tabClickHandler}
                />
            )
            }
        });

        return tabListEls;
    }

    return(
        <div aria-label="List of tabs" role="tablist" className="tab_list">
            {[...generateTabList()]}
        </div>
    )
}

export default TabGroup;