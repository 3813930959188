import React, { useEffect } from 'react';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import $ from 'jquery';

import './AnnouncementBanner.css';

const AnnouncementBanner = (props) => {
    let sanitizedText = DOMPurify.sanitize(props.text, {
        ADD_ATTR: ['target', 'rel']
    });
    // sanitizedText = props.text;

    useEffect(() => {
        if(!props.noAnimation){
            if(props.announcementBannerRef && props.announcementBannerRef.current){
                setTimeout(() => {
                    $(props.announcementBannerRef.current).slideDown('slow',() => document.querySelector('html').scrollTo({top:"0px",left:"0px",behavior:"smooth"}));
                }, 250);
            }
        }
    })

    return (
        <div 
            ref={props.announcementBannerRef} 
            className={['announcement-banner-wrappaer', props.wrapperClass ? props.wrapperClass : ''].join(' ')} 
            style={props.noAnimation ? {} : {display: 'none'}}
        >
            <label className={['announcement-banner-text', props.textClass ? props.textClass : ''].join(' ')}>
                {parse(sanitizedText)}
            </label>
            {
                props.bannerClose !== false ?
                <button 
                    className={['announcement-banner-close-btn', props.closeBtnClass ? props.closeBtnClass : ''].join(' ')} 
                    onClick={props.bannerCloseHandler}
                >
                    <svg width="20" height="20" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="12.6923" height="12.6923" transform="translate(0.561523 0.144531)" fill="white" fillOpacity="0.01" style={{"mixBlendMode":"multiply"}}/>
                        <path d="M10.0815 3.8717L9.52623 3.31641L6.90844 5.9342L4.29064 3.31641L3.73535 3.8717L6.35315 6.48949L3.73535 9.10729L4.29064 9.66258L6.90844 7.04478L9.52623 9.66258L10.0815 9.10729L7.46373 6.48949L10.0815 3.8717Z" fill="#161616"/>
                    </svg>
                </button>:''
            }
            {/* <label><Link to="/saas/webmethods-b2bapp">Click here</Link> to see the documentation for B2B's new runtime-focused theme.</label> */}
        </div>
    )
}

export default AnnouncementBanner;